import(/* webpackMode: "eager", webpackExports: ["CountLazy"] */ "/vercel/path0/app/count-lazy.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/dashboard.png");
;
import(/* webpackMode: "eager", webpackExports: ["IffyImage"] */ "/vercel/path0/app/iffy-image.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/antiwork-footer.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
